
import { 
  IonContent, 
  IonItem, 
  IonList, 
  IonMenu,
  IonMenuButton, 
  IonRouterOutlet,
  menuController
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {},
  setup() {
    return {
    IonContent, 
    IonItem, 
    IonList, 
    IonMenu,
    IonMenuButton,
    IonRouterOutlet
    }
  },
  methods: {
    openFirst() {
      menuController.enable(true, 'first');
      menuController.open('first');
    },
    openEnd() {
      menuController.open('end');
    },
    openCustom() {
      menuController.enable(true, 'custom');
      menuController.open('custom');
    },
    closeMenu() {
      menuController.close();
    }
  }
});
