

import { IonContent, IonHeader, IonPage, IonToolbar, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import LofiNameGeneratorComponent from "../components/LofiNameGeneratorComponent.vue";

export default defineComponent({
  name: 'LofiNameGenerator',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButton,
    LofiNameGeneratorComponent
  }
});
