
import { IonContent, IonHeader, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageNotFound',
  components: {
    IonContent,
    IonHeader,
    IonPage,
  }
  
});
