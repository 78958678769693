


import { defineComponent } from 'vue';
import Menu from './components/Menu.vue';
// import menuWidth from '@/components/state';

export default defineComponent({
  name: 'App',
  components: {
    Menu
  },
  setup() {
    return {
      // menuWidth
    }
  }
})
